import React from 'react';

const Link = (props) => (
  <a
    className="underline decoration-2 decoration-yellow-500/90 hover:decoration-red-400/90"
    href={props.to}
    target="_blank"
    rel="noreferrer"
  >
    {props.children}
  </a>
);

export { Link };
