import React from 'react';
import { Link } from '../components/Link';

const ProjectTitle = (props) => (
  <h3 className="text-xl sm:text-3xl">
    <Link to={props.to}>{props.children}</Link>
  </h3>
);

export { ProjectTitle };
